import React from "react";
import Education from "./Education";
import Experience from "./Experience";

const About = () => {
  return (
    <>
      <section className="main" id="about">
        <div className="main-headings">
          <div className="heading1">Profile</div>
          <div className="heading2">Know About Me</div>

        </div>
        <div className="profile">
        <div className="about-me">
          <div className="sub-heading" id="about">About Me</div>
          <p className="description">
            I am willing to learn new things. I am committed and hardworking. I
            am willing to learn new things. I am committed and hardworking. I am
            willing to learn new things. I am committed and hardworking.
          </p>
          <div className="contact-icon">
            <i class="fa fa-location-dot"><span className="contact-paragraph">Bhotebhal, Kathmandu</span></i><br/>
            <i class="fa fa-phone"><span className="contact-paragraph"><a href="tel:9860459348">+977 9860459348</a></span></i><br/>
            <i className="fa fa-envelope"><span className="contact-paragraph"><a href="mailto:shraddhadongol59@gmail.com">shraddhadongol59@gmail.com</a></span></i>
          </div>
        </div>
        <div className="edu">
          <Education />
        </div>
        </div>
      </section>
      <Experience/>
    </>
  );
};

export default About;
