import React from "react";
import "./ScrollDown.css";

const ScrollDown = () => {
  return (
    <>
      <div className="scroll-down">
        <a href="#about" className="mouse-wrapper">
          <i id="scroll" class="fa fa-angles-down"></i>
        </a>
      </div>
    </>
  );
};

export default ScrollDown;
