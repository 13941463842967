import React from "react";
import service1 from "../../assets/images/service-2.png";
import service2 from "../../assets/images/service-1.png";
import service3 from "../../assets/images/service-2.png";

const data = [
  {
    id: 1,
    image: service1,
    title: "Web Development",
    desc: "I can design and develop websites using HTML, CSS and JS. I can also develop website using Wordpress.",
  },
  {
    id: 2,
    image: service2,
    title: "Graphic Design",
    desc: "I can design banners, mockups and posters for different events using Adobe Illustrator and Photoshop",
  },
  {
    id: 3,
    image: service3,
    title: "UI/UX",
    desc: "I can design wire frames for different websites, web applications and mobile applications using Figma",
  },
];

const ServiceList = () => {
  return (
    <>
      <div className="service-container">
        {data.map(({ id, image, title, desc }) => {
          return (
            <div className="service-card" key={id}>
              <img src={image} alt="" className="service-img" /><br/><br/>
              <p className="service-heading">{title}</p><br/>
              <p className="location-paragraph">{desc}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ServiceList;
