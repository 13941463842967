import React from "react";

const data = [
  {
    id: 1,
    job: "Wordpress Developer",
    company: "Pragmatic Research and Innovation pvt.ltd",
    location:"Tinkune, Kathmandu",
    year: "17th Aug,2021 - 2nd Jan,2022",
  },
  {
    id: 1,
    job: "Graphic Design Intern",
    company: "Media Arch pvt.ltd",
    location:"Shantinagar, Kathmandu",
    year: "17th Aug,2021 - 2nd Jan,2022",
  },
];

const Experience = () => {
  return (
    <>
      <section className="main" id="work">
        <div className="main-headings">
          <div className="heading1">Experience</div>
          <div className="heading2">My Work Experience</div>
        </div>
        <div className="exp-container">
          <div className="exp-sub  flex-item fadeIn">
            {data.map(({ id, job, year, company, location }) => {
              return (
                <div className="exp">
                  <ul class="timeline" key={id}>
                  <li>
                    <span className="job">{job}</span>
                    <p className="course">{company}</p><br/>
                    <i class="fa fa-location-dot"><span className="contact-paragraph">{location}</span></i>
                    <i class="fa-regular fa-calendar"><span className="contact-paragraph">{year}</span></i>
                  </li>
                </ul>
                 </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
