import React from 'react'
import ServiceList from './ServiceList'
// import MultipleItems from './MultipleItems'

const Services = () => {
  return (
   <> 
    <section className="main" id='service'>
        <div className="main-headings">
          <div className="heading1">Services</div>
          <div className="heading2">What I do</div>
        </div>
    {/* <MultipleItems /> */}
   <ServiceList/> 
   </section> 
   </>
  )
}

export default Services