import React from 'react';
import "./skill.css";
import Skillbars from './Skillbars';

const Skill = () => {
  return (
    <> 
    <section className="main" id='skill'>
        <div className="main-headings">
          <div className="heading1">Skills</div>
          <div className="heading2">What I know</div>
        </div>
  <Skillbars/>

   </section> 
   </>
  )
}

export default Skill