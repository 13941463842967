import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
   <> <hr/>
    <div className='footer-container'>
            <span className='copyright-line'> &copy; Copyright. All rights reserved.</span>
            <div className="footer-socials">
        <a href="https://www.linkedin.com/in/shraddha-dongol-a76a10217/" className="profile-social-link" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href="https://www.instagram.com/shraddha_aa_/" className="profile-social-link" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="https://github.com/0Shraddha" className="profile-social-link" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-github"></i>
        </a>
        <a href="https://www.behance.net/shraddhadongol" className="profile-social-link" target="_blank" rel="noreferrer">
          <i class="fa-brands fa-behance"></i>
        </a>
      </div>
    </div>
    </>
  )
}

export default Footer