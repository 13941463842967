import React from 'react'
import Profile from './Components/Profile/Profile'
import "./App.css";
import About from './Components/About/About';
import Services from './Components/Service/Services';
import Projects from './Components/Project/Projects';
import Skill from './Components/Skills/Skill';
import Sidebar from './Components/Sidebar/Sidebar';
// import Cv from './Components/Profile/Cv';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from './Components/Footer/Footer';
// import Sidebar1 from './Components/Sidebar/Sidebar1';


const App = () => {

  return (
    <>
    <Sidebar />
    {/* <Sidebar1 /> */}
    <Profile/>
    <About/>
    <Services/>
    <Skill/>
    <Projects/>
    <Footer/>
    </>
  )
}

export default App