import React from "react";

const data = [
  {
    id: 1,
    course: "Bachelor in Computer Applications",
    year: "2020 - Present",
    location: "Kathmandu Model College, Bagbazar",
  },
  {
    id: 2,
    course: "+2, Science",
    year: "2018 - 2020",
    location: "DAV College, Jawalakhel",
    gpa: "3.63",
  },
  {
    id: 3,
    course: "SEE",
    year: "2005 - 2008",
    location: "Morning Glory High School, Bhotebahal",
    gpa: "3.7",
  },
];

const Education = () => {
  return (
    <>
      <div className="edu-container">
        <p className="sub-heading" id="edu">Education</p>
        <div className="edu-sub">
          {data.map(({ id, course, year, location, gpa }) => {
            return (
              <ul class="timeline1" key={id}>
                <li>
                <p className="course">{course}</p>
                  <p className="date">{year}</p>
          
<p className="location-paragraph">{location}</p>
                  {gpa ? (
                    <p className="location-paragraph">GPA: {gpa}</p>
                  ) : (
                    <> </>
                  )}
                </li><br/>
              </ul>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Education;
