import React from 'react'
import "./Profile.css";
import cv from "../../assets/images/CV_ShraddhaDongol.pdf";


const Cv = () => {
  return (
     <div id="profile" className='cv-btn'>
        <a href={cv} target='_blank' rel="noreferrer"><button>View CV</button></a>
    </div>
  )
}

export default Cv