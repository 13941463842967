import React from "react";
// import Me from "../../assets/images/mee.jpg";
import HeaderSocial from "./HeaderSocial";
import Cv from "./Cv";
import ScrollDown from "./ScrollDown";
import "./Profile.css";

const Profile = () => {
  return (
    <>
    <Cv/>
      <div className="main" >
        <div className="profile-main-1">
          {/* <img src={Me} alt="Profile" className="profile-pic" /> */}
          <p className="profile-desc">Hello, I am</p>
            <h1 className="profile-name">Shraddha Dongol</h1>
            <div className="socials">
          <HeaderSocial />
          </div>
          <ScrollDown />
        </div>
      </div>
    </>
  );
};

export default Profile;
