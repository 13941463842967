import React from 'react'
import "./sidebar.css"
//import { useState } from "react";
// import { Link, Route,Routes } from 'react-router-dom';
// import About from '../About/About';
// import Profile from '../Profile/Profile';
// import Projects from '../Project/Projects';
// import Services from '../Service/Services';
// import Skill from '../Skills/Skill';
// import Experience from '../About/Experience';


const Sidebar = () => {
// const [active,setActive]=useState(false);
// const handleClick = () =>{
//   setActive(!active);
// };

  return (
    <>
    <div className='sidebar-container'>
        <div> <a href='#profile'><button type="button"  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="About"><i class="fa fa-user"></i></button></a></div>
        {/* <div><Link to='/'><button type="button" onClick={handleClick} style={{color : active ? "#FFB016" : "#0F1F45"}} class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="About"><i class="fa fa-user"></i></button></Link></div> */}
        <div> <a href='#about'><button type="button"  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Education"><i class="fa fa-book"></i></button></a></div>
        {/* <div><Link to="/about"><button type="button" onClick={handleClick}  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Education"><i class="fa fa-book"></i></button></Link></div> */}
        <div> <a href='#work'><button type="button"  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Experience"><i class="fa fa-briefcase"></i></button></a></div>
        {/* <div><Link to="/experience"><button type="button" onClick={handleClick}  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Experience"><i class="fa fa-briefcase"></i></button></Link></div> */}
        <div> <a href='#service'><button type="button"   class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Services"><i class="fa fa-star"></i></button></a></div>
        {/* <div><Link to="/services"><button type="button" onClick={handleClick}  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Experience"><i class="fa fa-star"></i></button></Link></div> */}
        <div> <a href='#skill'><button type="button"   class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Skills"><i class="fa fa-list-check"></i></button></a></div>
        {/* <div><Link to="/skills"><button type="button" onClick={handleClick}  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Skills"><i class="fa fa-list-check"></i></button></Link></div> */}
        <div> <a href='#project'><button type="button"  class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Projects"><i class="fa fa-product-hunt"></i></button></a></div>
        {/* <div><Link to="/projects"><button type="button" onClick={handleClick} style={{color : active ? "#FFB016" : "#0F1F45"}} class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Projects"><i class="fa fa-product-hunt"></i></button></Link></div> */}


    </div>

    <div>
    {/* <Routes>
      <Route path='/' element={<Profile/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/experience' element={<Experience/>}/>

      <Route path='/services' element={<Services/>}/>
      <Route path='/skills' element={<Skill/>}/>
       <Route path='/projects' element={<Projects/>}/>
    </Routes> */}
    </div>
    </>
  )
}

export default Sidebar