import React from "react";

const Skillbars = () => {
  return (
    <>
    <div className="skills">
      <div class="skill-bars">
        <div class="bar">
          <div class="info">
            <span>HTML</span>
          </div>
          <div class="progress-line html">
            <span></span>
          </div>
        </div>

        <div class="bar">
          <div class="info">
            <span>CSS</span>
          </div>
          <div class="progress-line css">
            <span></span>
          </div>
        </div>

        <div class="bar">
          <div class="info">
            <span>JS-React</span>
          </div>
          <div class="progress-line js">
            <span></span>
          </div>
        </div>

        <div class="bar">
          <div class="info">
            <span>C</span>
          </div>
          <div class="progress-line c">
            <span></span>
          </div>
        </div>

        <div class="bar">
          <div class="info">
            <span>DataBase-SQL</span>
          </div>
          <div class="progress-line mysql">
            <span></span>
          </div>
        </div>
      </div>
      <div class="skill-bars">
        <div class="bar">
          <div class="info">
            <span>Java-Spring Boot</span>
          </div>
          <div class="progress-line java">
            <span></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Wordpress/PHP</span>
          </div>
          <div class="progress-line wp">
            <span></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Adobe Illustrator</span>
          </div>
          <div class="progress-line adobe">
            <span></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Adobe Photoshop</span>
          </div>
          <div class="progress-line adobe1">
            <span></span>
          </div>
        </div>
        <div class="bar">
          <div class="info">
            <span>Figma</span>
          </div>
          <div class="progress-line figma">
            <span></span>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Skillbars;

// import React from "react";
// const data = [
//   {
//     id: 1,
//     skill: "HTML",
//   },
//   {
//     id: 2,
//     skill: "CSS",
//   },
//   {
//     id: 3,
//     skill: "JS-React",
//   },
//   {
//     id: 4,
//     skill: "C",
//   },
//   {
//     id: 5,
//     skill: "Java-Spring Boot",
//   },
//   {
//     id: 6,
//     skill: "Database-MySql",
//   },
//   {
//     id: 7,
//     skill: "Wordpress/PHP",
//   },
//   {
//     id: 8,
//     skill: "Adobe Illustrator",
//   },
//   {
//     id: 9,
//     skill: "Adobe Photoshop",
//   },
//   {
//     id: 10,
//     skill: "Figma",
//   }
// ];

// const Skillbars = () => {
//   return (
//     <>
//       <div class="skill-bars">
//         {data.map(({ id, skill }) => {
//             return(
//           <div class="bar" key={id}>
//             <div class="info paragraph">
//               <span>{skill}</span>
//             </div>
//             <div class="progress-line html">
//               <span></span>
//             </div>
//           </div>
//           );
//         })}
//         </div>
//     </>
//   );
// };

// export default Skillbars;
