import React from 'react'
import ProjectList from './ProjectList'

const Projects = () => {
  return (
    <>
    <section className="main" id='project'>
        <div className="main-headings">
          <div className="heading1">Projects</div>
          <div className="heading2">My Recent Works</div>
        </div>
   <ProjectList/> 
   </section> 
    </>
  )
}

export default Projects