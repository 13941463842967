import React, { useState } from 'react';
import "./ProjectList.css";
import gd1 from "../../assets/images/gd1.jpg";
import gd2 from "../../assets/images/gd2.png";
import gd3 from "../../assets/images/gd3.png";
// import gd4 from "../../assets/images/gd4.png";

import wd1 from "../../assets/images/wd1.png";
import wp1 from "../../assets/images/wp1.png";
import uiux from "../../assets/images/uiux.png";

import pdf1 from "../../assets/images/medisync.pdf"
import pdf2 from "../../assets/images/hotelenroll.pdf"
import pdf3 from "../../assets/images/FestivalBanners.pdf"
import pdf4 from "../../assets/images/EventBanners.pdf"
import pdf5 from "../../assets/images/SkinFreakBanners.pdf"



const menu =[{
  id:1,
  image: gd1,
  pdf:pdf3,
  name:"Festival Banners",
  category:"Graphic Design"
},{
  id:2,
  image: wp1,
  pdf: "https://nesfield.edu.np/",
  name:"Wordpress Website",
  category:"Wordpress"
},{
  id:5,
  image: gd2,
  pdf:pdf5,
  name:"Branding",
  category:"Graphic Design"
},{
  id:3,
  image: wd1,
  pdf:pdf1,
  name:"Web Application",
  category:"Web Development"
},{
  id:4,
  image: uiux,
  pdf:pdf2,
  name:"Wireframe",
  category:"uiux"

},{
  id:6,
  image: gd3,
  pdf:pdf4,
  name:"Event Banners",
  category:"Graphic Design"
}]

const ProjectList = () => {
  const [items, setItems] = useState(menu);

  const filterItem = (categoryItem) => {
    const updatedItems = menu.filter((curElem) => {
      return curElem.category === categoryItem
    })
    setItems(updatedItems);
  }
  return (
    <>
    <hr />
    <br/>
    <div className="filter-container">
      <div className='filter-btn'>
        <button className='btn' onClick={()=>setItems(menu)}>All</button>
        <button className='btn'onClick={()=>filterItem('Web Development')}>Web Development</button>
        <button className='btn' onClick={()=>filterItem('Graphic Design')}>Graphic Design</button>
        <button className='btn'onClick={()=>filterItem('uiux')}>UI/UX</button>
        <button className='btn'onClick={()=>filterItem('Wordpress')}>Wordpress</button>
      </div>
      <div className='items main'>
        {items.map(({ id, image, name, pdf,category }) => {
              return (
                <div className='each-item' key={id}>
                <a href={pdf} target="_blank" rel='noreferrer'><img src={image} alt="project" className='item-img'/>
                <p className='title'>{name}</p></a>
            </div>
              );
            })}
    </div>
</div>
<br/>
<br/>
    </>
  )
}

export default ProjectList


